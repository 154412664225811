.App {
  /* text-align: center; */
  background-color: #e1dfd9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  font-family: "Monaco";
  color: rgb(17, 16, 16);
}
.App-body {
  background-color: #e1dfd9;
}

.App-link {
  color: #209d50;
}

.greenButton {
  color: #209d50;
  border-radius: 0;
  background-color: "transparent";
  padding-top: 20;
  padding-bottom: 20;
  padding-right: 40;
  padding-left: 40;
  border-color: "#209d50";
  border-width: 3;
}

/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
